import React from 'react'
import { Box, Heading, Grid, Text } from 'theme-ui'
import { useStaticQuery, graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import { SEO } from 'components'

function Contact() {
  const imagesQuery = useStaticQuery(graphql`
    query ContactImages {
      droneShot: file(relativePath: { eq: "drone-shot@2x.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1680) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <SEO title="Contact" />
      <Box px={['m', null, 'l']}>
        <Box
          py={['xl', 'xxl', 'xxxl', 'xxxxxl']}
          variant="boxes.figure"
          mx="auto"
        >
          <Box mb={['xl', 'xxl', 'xxxl', 'xxxxxl']}>
            <Heading variant="heading.xl" mb={['xl', 'xxl', 'xxxl']}>
              Get in touch with us
            </Heading>
            <Grid columns={[null, '1fr 1fr']}>
              <Box>
                <Box mb="xl">
                  <Text variant="subhead" mb="s" sx={{ color: 'green.dark' }}>
                    Phone
                  </Text>
                  <Text
                    as="a"
                    href="tel: 015242 41274"
                    sx={{ textDecoration: 'none', color: 'black.100' }}
                  >
                    015242 41274
                  </Text>
                </Box>
                <Box>
                  <Text variant="subhead" mb="s" sx={{ color: 'green.dark' }}>
                    Email
                  </Text>
                  <Text
                    as="a"
                    href="mailto:bookings@thestationinnribblehead.com"
                    sx={{ textDecoration: 'none', color: 'black.100' }}
                  >
                    bookings@thestationinnribblehead.com
                  </Text>
                </Box>
              </Box>
              <Box>
                <Text variant="subhead" mb="s" sx={{ color: 'green.dark' }}>
                  Location
                </Text>
                <Text as="p" mb="m">
                  The Station Inn, Ribblehead
                  <br />
                  Low Sleights Rd, Carnforth
                  <br />
                  LA6 3AS
                </Text>
                <Text
                  variant="subhead"
                  as="a"
                  href="https://goo.gl/maps/iRCwPZSKwovCqsGG6"
                  target="_blank"
                  sx={{ textDecoration: 'none', color: 'black.100' }}
                >
                  Find us on Google maps
                </Text>
              </Box>
            </Grid>
          </Box>
          <GatsbyImage
            fluid={imagesQuery.droneShot.childImageSharp.fluid}
            style={{ display: 'block' }}
          />
        </Box>
      </Box>
    </>
  )
}

export default Contact
